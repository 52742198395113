<script setup>
const props = defineProps(["togglefaq", "results"])
</script>
<template>
    <div>
        <div class="container">
            <img class="logo" src="/public/logo.svg"></img>
        </div>
        <div class="container info text-center">
            <div class="row">
                <h1 class="col val">
                    <b>KDY?</b>
                    <br />
                    31.5.2025
                </h1>
                <h1 class="col val">
                    <b>V KOLIK?</b>
                    <br />
                    9:00
                </h1>
                <h1 class="col val">
                    <b>KDE?</b>
                    <br />
                    Orlické hory
                </h1>
                <h1 class="col val">
                    <b>CENA</b>
                    <br />
                    do 24.12.2024
                    <br />
                    690 kč*
                </h1>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="text col-xl-6">
                    <p>Vítej člověče! Momentálně se nacházíš na oficiální stránce závodu ALKÁTOR RACE.</p>

                    <p>A co že jsme si to zase vymysleli!? </p>

                    <p>Jedná se o překážkový závod u nás v Orlických horách. Celkem máme připravených kolem 30 překážek a trať měří kolem 4 km. V závodě tě čeká pěkných pár panáků, kdy počty se pohybují od 3-15 kousků podle toho jak dobře si na překážkách povedeš. Dále máme v závodě překážku zvanou "Čekárna" na které si deset minut odpočneš a dáš si oddychové pivínko na nejkrásnějších místech v neznámém místě. Čekárny tě v závodě čekají hned čtyři. </p>

                    <p>Pokud v průběhu závodu dostaneš pocit, že je to nad tvé síly, vždy budeš mít tu možnost přestoupit do kategorie Alkátor Race LIGHT, kde sice ztratíš možnost stanout na stupních vítězů, ale budeš moci plně regulovat svou obtížnost závodu.
                    Takže když budeš mít špatný den, nikdo tě nebude do ničeho nutit a ty si užiješ atmosféru akce na plné pecky.</p>

                    <p>Našim smělým alkátorkám nebudeme nic extra zjednodušovat, pouze na čekárnách budou konzumovat poloviční dávky piva(pokud budou chtít).
                    Pravděpodobně bude otevřena i kategorie NEAlkátor Race - zaměřena na řidiče, abstinenty a nezletilé.</p>

                    <p>Pro alkátory/rky na stupních vítězů čekají úžasné ceny.</p>


                    <p>* Cena bude postupně zvedána.</p>

                    <p>Rozhodně se podívej na FAQ sekci, zde na stránce, kde najdeš důležité informace týkající se závodu ALKÁTOR RACE.</p>
                    <p>Sportem ku chlastu!</p>
                    <iframe width="100%" height="500px" src="https://www.youtube.com/embed/INYakiBnVNk?si=Nx9QtADyt_4XI5BC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="true" />
                </div>
                <div class="col-xl-6">
                    <h1 class="text">Výsledky 2. ročníku (Podzim 2024)</h1>
                    <table class="table results">
                        <thead>
                            <tr>
                                <th>Umístění</th>
                                <th>Čas</th>
                                <th>Kategorie</th>
                                <th>Startovní číslo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user in props.results" :key="user.starting_number">
                                <th>{{user.order}}</th>
                                <td>{{user.duration}}</td>
                                <td>{{user.alkator_category}}</td>
                                <td>{{user.starting_number}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="container text text-center">
            <h2>Stále nevíš, jestli se zúčastníš? Přečti si náš <button type="button" class="btn btn-warning" @click="props.togglefaq">FAQ</button></h2>
        </div>
        <div class="container text text-center">
            <h1>Registrujte si účet a poté vám bude umožněno registrovat samotného závodníka.</h1>
        </div>
        <div class="container text-center social">
            <div class="row">
                <a class="col-lg-3" :style='{"color": "black"}' href="mailto:info@alkator.cz"><b>info@alkator.cz</b></a>
                <a class="col-lg-3" href="https://www.facebook.com/AlkatorRace"><img height="40px" src="/public/fb_logo.svg" /></a>
                <a class="col-lg-3" href="https://www.youtube.com/@AlkatorRace"><img height="40px" src="/public/youtube_logo.svg"/></a>
                <a class="col-lg-3" href="https://www.instagram.com/alkator_race/"><img height="40px" src="/public/instagram_logo.svg" /></a>
            </div>
        </div>
        <div class="container video">
            <iframe width="100%" height="800px" src="https://www.youtube.com/embed/v3r2IAPdFFQ?si=kkh2_BBJXw56gVaP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="true"></iframe>
        </div>
    </div>
</template>